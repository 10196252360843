import { useState } from "react";

import Slide from "./Slide";
import SlideDots from "./SlideDots";
import useSlides from "../../hooks/use-slides";

import classes from "./Slider.module.css";
import SliderButton from "./SliderButton";

const Slider = (props) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const { data: slideData } = useSlides();

  const maxSlide = slideData.length;

  const previousSlide = () => {
    setActiveSlideIndex((prev) => {
      if (prev === 0) {
        return maxSlide - 1;
      } else {
        return prev - 1;
      }
    });
  };

  const nextSlide = () => {
    setActiveSlideIndex((prev) => {
      if (prev === maxSlide - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  const goToSlide = (slideIndex) => {
    setActiveSlideIndex(slideIndex);
  };

  const slides = slideData.map((s, i) => (
    <Slide
      key={i}
      title={s.title}
      body={s.body}
      glyph={s.glyph}
      style={{ transform: `translateX(${100 * (i - activeSlideIndex)}%)` }}
    />
  ));

  return (
    <div className={classes.wrapper}>
      <div className={classes.slider}>{slides}</div>
      <SlideDots
        onSlideChange={goToSlide}
        slides={slides}
        activeIndex={activeSlideIndex}
      />
      <SliderButton position="left" onClick={previousSlide} />
      <SliderButton position="right" onClick={nextSlide} />
    </div>
  );
};

export default Slider;
