import { Fragment, useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { GrClose } from "react-icons/gr";

import CSSTransition from "react-transition-group/CSSTransition";

import Backdrop from "./Backdrop";

import classes from "./Modal.module.css";

const portalElement = document.getElementById("overlay");

const ModalOverlay = (props) => {
  const nodeRef = useRef();

  const transitionClass = {
    enterActive: classes.show,
    exitActive: classes.hide,
  };

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      in={props.show}
      timeout={300}
      classNames={transitionClass}
      nodeRef={nodeRef}
    >
      <div className={classes.modal} ref={nodeRef}>
        <button className={classes.btn__close} onClick={props.onClose}>
          <GrClose />
        </button>
        <div className={classes.content}>{props.children}</div>
      </div>
    </CSSTransition>
  );
};

const Modal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop show={props.show} onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay show={props.show} onClose={props.onClose}>
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
