import { useContext } from "react";
import MobileContext from "../../store/mobile-context";
import classes from "./SectionColumn.module.css";

const SectionColumn = (props) => {
  const mobileCtx = useContext(MobileContext);
  const columnClasses = [
    classes.column,
    mobileCtx.isMobile && props.isImage ? classes.column__image : "",
  ];

  return <div className={columnClasses.join(" ")}>{props.children}</div>;
};

export default SectionColumn;
