import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import { NavLink } from "react-router-dom";

import { BiMenu } from "react-icons/bi";

import MobileContext from "../../store/mobile-context";
import Logo from "../Logo/Logo";
import iconImg from "../../assets/icon.png";

import {
  DEFAULT_MOBILE_VIEWPORT,
  DEFAULT_TIMEOUT,
} from "../../utils/constants";
import classes from "./Header.module.css";
import Backdrop from "../UI/Backdrop";

const portalElement = document.getElementById("overlay");

const Header = (props, ref) => {
  const mobileCtx = useContext(MobileContext);
  const [mobileNavIsShowing, setMobileNavIsShowing] = useState(false);

  // CSS class declarations
  const headerClass = props.sticky ? classes.sticky : "";
  const navListWrapperClass = [
    mobileCtx.isMobile ? classes["nav__wrapper--mobile"] : classes.nav__wrapper,
    mobileNavIsShowing ? classes.nav__shadow : "",
  ];
  const mobileNavButtonClass = [
    classes.btn__mobile_nav,
    mobileCtx.isMobile ? classes.show : classes.hide,
  ];

  // Event handlers
  const smoothScrollHandler = (event) => {
    if (mobileNavIsShowing) setMobileNavIsShowing(false);

    const target = event.currentTarget.dataset.scrollTo;

    setTimeout(() => {
      props.onSmoothScroll(target);
    }, DEFAULT_TIMEOUT);
  };

  const openNavHandler = (event) => {
    setMobileNavIsShowing(true);
  };

  const closeNavHandler = (event) => {
    setMobileNavIsShowing(false);
  };

  const mobileNavButton = (
    <div className={mobileNavButtonClass.join(" ")}>
      <BiMenu onClick={openNavHandler} />
    </div>
  );

  const contactHandler = (event) => {
    if (mobileNavIsShowing) setMobileNavIsShowing(false);
    props.onContact(event);
  };

  const navMenu = (
    <div
      className={navListWrapperClass.join(" ")}
      style={mobileNavIsShowing ? { transform: "translateX(0%)" } : {}}
    >
      <ul>
        <NavLink to="/" onClick={smoothScrollHandler} data-scroll-to="#about">
          <li>About</li>
        </NavLink>

        <NavLink
          to="/"
          onClick={smoothScrollHandler}
          data-scroll-to="#services"
        >
          <li>Services</li>
        </NavLink>
        <NavLink to="/" onClick={contactHandler}>
          <li>Contact</li>
        </NavLink>
      </ul>
      {mobileCtx.isMobile && (
        <div className={classes.icon_container}>
          <img src={iconImg} alt="InfoRacer logo icon" />
        </div>
      )}
    </div>
  );

  return (
    <header className={headerClass}>
      {mobileCtx.isMobile &&
        ReactDOM.createPortal(
          <div className={classes.nav}>
            {navMenu}
            <Backdrop show={mobileNavIsShowing} onClose={closeNavHandler} />
          </div>,
          portalElement
        )}
      <nav className={classes.nav}>
        <div className={classes.logo}>
          <NavLink to="/">
            <Logo />
          </NavLink>
        </div>
        {mobileCtx.isMobile ? mobileNavButton : navMenu}
      </nav>
    </header>
  );
};

export default Header;
