import React, { Fragment, useContext } from "react";
import { Toaster } from "react-hot-toast";

import Router from "./Router";
import MobileContext from "./store/mobile-context";

import "./App.css";

function App() {
  const mobileCtx = useContext(MobileContext);

  return (
    <Fragment>
      <Router />
      <Toaster
        toastOptions={{
          style: { fontSize: `${!mobileCtx.isMobile ? "1rem" : ".6rem"}` },
        }}
      />
    </Fragment>
  );
}

export default App;
