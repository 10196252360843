import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth-slice";
import clientReducer from "./client-slice";

const store = configureStore({
  reducer: { auth: authReducer, client: clientReducer },
});

export default store;
