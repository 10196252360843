import React, { Fragment, useEffect } from "react";

import classes from "./Redirect.module.css";

const Redirect = (props) => {
  const { url } = props;

  useEffect(() => {
    setTimeout(() => {
      window.location.href = url;
    }, 600);
  }, [url]);

  return (
    <div className={classes.redirect}>
      <h2>Redirecting...</h2>
    </div>
  );
};

export default Redirect;
