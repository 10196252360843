import React, { useEffect, useState, useContext } from "react";

import MobileContext from "../../store/mobile-context";

import classes from "./Section.module.css";

const Section = React.forwardRef((props, ref) => {
  const [showSection, setShowSection] = useState(false);
  const mobileCtx = useContext(MobileContext);

  useEffect(() => {
    if (showSection) return;

    if (props.isVisible) {
      setShowSection(true);
    }
  }, [props.isVisible]);

  const sectionClasses = [
    classes.section,
    props.display === "flex" ? classes["section--flex"] : "",
    showSection ? classes.show : classes.hide,
  ];

  // Section background when viewing on mobile
  const backgroundStyles =
    mobileCtx.isMobile && props.image
      ? {
          backgroundImage: `linear-gradient( rgba(255, 255, 255, 0.875), rgba(255, 255, 255, 0.875) ), url("${props.image}")`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: "center",
          backgroundSize: "140%",
        }
      : {};

  return (
    <section
      style={backgroundStyles}
      className={sectionClasses.join(" ")}
      id={props.id}
      ref={ref}
    >
      <div className={classes.container}>{props.children}</div>
    </section>
  );
});

export default Section;
