import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  request: { status: null, error: null },
  initialFormValues: null,
};

const clientSlice = createSlice({
  name: "client",
  initialState: initialState,
  reducers: {
    setInitialFormValues(state, action) {
      state.initialFormValues = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setRequestError(state, action) {
      state.request.error = action.payload;
    },
    setRequestStatus(state, action) {
      state.request.status = action.payload;
    },
    clear(state) {
      state.data = initialState.data;
      state.request = initialState.request;
      state.initialFormValues = initialState.InitialFormValues;
    },
  },
});

export const clientActions = clientSlice.actions;

export default clientSlice.reducer;
