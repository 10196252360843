import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./store/index";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { MobileContextProvider } from "./store/mobile-context";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey="6Ld54_gdAAAAAGNtoUQU3t7S-5larWYr4QL8fipS"
        scriptProps={{ async: true, defer: true }}
      >
        <MobileContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MobileContextProvider>
      </GoogleReCaptchaProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
