import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useOutletContext } from "react-router-dom";

import Section from "./Section";
import SectionColumn from "./SectionColumn";
import Slider from "../Slider/Slider";
import Button from "../Button/Button";

import useElementOnScreen from "../../hooks/use-element-on-screen";

import helpdeskImage from "../../assets/sa-helpdesk.png";
import consoleImage from "../../assets/sa-console.png";
import upgradeImage from "../../assets/sa-upgrade.png";

import classes from "./Sections.module.css";

const observerOptions = {
  root: null,
  threshold: 0.35,
  rootMargin: "0px",
};

const Sections = (props) => {
  const [openContactModalHandler] = useOutletContext();
  const [secOneRef, secOneIsVisible] = useElementOnScreen(observerOptions);
  const [secTwoRef, secTwoIsVisible] = useElementOnScreen(observerOptions);
  const [secThreeRef, secThreeIsVisible] = useElementOnScreen(observerOptions);
  const [secFourRef, secFourIsVisible] = useElementOnScreen(observerOptions);

  return (
    <Fragment>
      <Section
        display="flex"
        isVisible={secOneIsVisible}
        ref={secOneRef}
        image={helpdeskImage}
      >
        <SectionColumn isImage>
          <img src={helpdeskImage} />
        </SectionColumn>
        <SectionColumn>
          <h1>
            Transforming IT Through Sharp Minds, Professional Service & Personal
            Support
          </h1>

          <div className={classes.container__button}>
            <Button primary onClick={openContactModalHandler}>
              Get a Free Quote
            </Button>

            <a href="https://quickassist.screenconnect.com">
              <Button>Instant Support</Button>
            </a>
          </div>
        </SectionColumn>
      </Section>
      <Section
        display="flex"
        id="about"
        isVisible={secTwoIsVisible}
        ref={secTwoRef}
        image={consoleImage}
      >
        <SectionColumn>
          <h2>
            <span className="highlight">Who We Are</span>
          </h2>
          <p>
            <b>InfoRacer Technologies</b> is an Information Technology services
            provider that offers Managed and Traditional I.T services throughout
            the east coast of the United States.
          </p>
          <br />
          <p>
            We have extensive experience in Network Administration, Network
            Security, Cloud Services, Backup and Disaster Recovery, Project
            Management and much more.
          </p>
        </SectionColumn>
        <SectionColumn isImage>
          <img src={consoleImage} />
        </SectionColumn>
      </Section>
      <Section
        image={upgradeImage}
        display="flex"
        isVisible={secThreeIsVisible}
        ref={secThreeRef}
      >
        <SectionColumn isImage>
          <img src={upgradeImage} />
        </SectionColumn>
        <SectionColumn>
          <h2>
            <span className="highlight">Our Mission</span>
          </h2>
          <p>
            To help businesses use Information Technology to reduce operational
            costs, increase productivity, and better achieve their goals.
          </p>
          <br />
          <p>
            At <b>InfoRacer Technologies</b>, we have the technical knowledge
            and industry experience required to help your business get the most
            out of Informaton Technology.
          </p>
        </SectionColumn>
      </Section>
      <Section
        display="block"
        id="services"
        isVisible={secFourIsVisible}
        ref={secFourRef}
      >
        <Slider />
      </Section>
    </Fragment>
  );
};

export default Sections;
