import { useContext } from "react";
import {
  BsCpu,
  BsHddNetworkFill,
  BsHeadset,
  BsDiagram3,
  BsGear,
} from "react-icons/bs";

import MobileContext from "../store/mobile-context";

const useSlides = () => {
  const mobileCtx = useContext(MobileContext);
  const slides = [
    {
      title: "Core Services",
      body: [
        "Data Backup",
        "AntiVirus",
        "AntiSpam",
        `Resource ${!mobileCtx.isMobile ? "Monitoring" : "Mon."}`,
        `Patch ${!mobileCtx.isMobile ? "Management" : "Mgmt."}`,
        "Disaster Recovery",
        "Cloud Services",
        `Automated ${!mobileCtx.isMobile ? "Maintenance" : "Maint."}`,
      ],
      glyph: <BsCpu />,
    },
    {
      title: "Network Administration",
      body:
        "Our preferred method of Network Administration is standarization and implementation of best practices. We'll make sure your network is set up right and with proper documentation.",
      glyph: <BsHddNetworkFill />,
    },
    {
      title: "Helpdesk",
      body:
        "Our support team is always available to help with any issues that might arise. If we cannot remotely resolve any issue in a reasonable amount of time, we will dispatch a support engineer to come on-site.",
      glyph: <BsHeadset />,
    },
    {
      title: "Virtual CIO",
      body:
        "Technology and business requirements are always changing. InfoRacer will help you choose the technologies that will help you better achieve your goals.",
      glyph: <BsDiagram3 />,
    },
    {
      title: "Professional Services",
      body:
        "While a Managed Services solution covers your existing infrastructure there will always be new projects. InfoRacer Technologies will design, implement and manage these projects for you.",
      glyph: <BsGear />,
    },
  ];

  return { data: slides };
};

export default useSlides;
