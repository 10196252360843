import { config, DEFAULT_MOBILE_VIEWPORT } from "../utils/constants";
import toast from "react-hot-toast";

var base64 = require("base-64");

export const API_URL = config.API_URL;
const LOGIN_PATH = "auth/login/";
export const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": `${API_URL}`,
};

export const contact = async (_, formData) => {
  const payload = {
    method: "POST",
    headers: { ...DEFAULT_HEADERS },
    body: JSON.stringify(formData),
  };

  const response = await fetch(`${API_URL}contact/`, payload);

  if (!response.ok) {
    throw new Error("Error sending contact form.");
  }
};

export const createContract = async (token, contractData) => {
  const payload = {
    method: "POST",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
    body: JSON.stringify(contractData),
  };

  const response = await fetch(`${API_URL}contracts/`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error("Failed to create contract.");
  }

  return data;
};

export const updateContract = async (token, contractData) => {
  const payload = {
    method: "PUT",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
    body: JSON.stringify(contractData),
  };

  const response = await fetch(
    `${API_URL}contracts/${contractData.id}/`,
    payload
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error("Failed to update the contract.");
  }

  return data;
};

export const getContract = async (token, contractId) => {
  const payload = {
    method: "GET",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
  };

  const response = await fetch(`${API_URL}contracts/${contractId}/`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error("Failed to fetch contract.");
  }

  return data;
};

export const removeContract = async (token, contractId) => {
  const payload = {
    method: "DELETE",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
  };

  const response = await fetch(`${API_URL}contracts/${contractId}`, payload);
  if (!response.ok || response.status !== 204) {
    throw new Error(`Failed to delete contracts with id ${contractId}`);
  }
};

export const getContracts = async (token, clientId = null) => {
  const payload = {
    method: "GET",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
  };

  const response = await fetch(
    `${API_URL}contracts/?client=${clientId}`,
    payload
  );
  const data = await response.json();

  if (!response.ok) {
    throw new Error("Failed to fetch contracts.");
  }

  return data;
};

export const removeTask = async (token, taskId) => {
  const payload = {
    method: "DELETE",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
  };

  const response = await fetch(`${API_URL}tasks/${taskId}`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error(`Failed to delete task with id ${taskId}`);
  }

  return data;
};

export const updateTask = async (token, taskData) => {
  const payload = {
    method: "PUT",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
    body: JSON.stringify(taskData),
  };

  const response = await fetch(`${API_URL}tasks/${taskData.id}/`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error("Failed to update task.");
  }

  return data;
};

export const getTask = async (token, taskId) => {
  const payload = {
    method: "GET",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
  };

  const response = await fetch(`${API_URL}tasks/${taskId}`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error("Unable to fetch task.");
  }

  return data;
};

export const createTask = async (token, taskData) => {
  const payload = {
    method: "POST",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
    body: JSON.stringify(taskData),
  };

  const response = await fetch(`${API_URL}tasks/`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error("Failed to create task.");
  }

  return data;
};

export const getTasks = async (token, clientId) => {
  const payload = {
    method: "GET",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
  };

  // Omit clientId if it is null
  const fixedApiUrl = `${API_URL}tasks/${
    clientId ? `?client=${clientId}` : ""
  }`;

  const response = await fetch(fixedApiUrl, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error("Failed to fetch tasks.");
  }

  return data;
};

export const createClient = async (token, clientData) => {
  const payload = {
    method: "POST",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
    body: JSON.stringify(clientData),
  };

  const response = await fetch(`${API_URL}clients/`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error("Failed to create client.");
  }

  return data;
};

export const updateClient = async (token, clientData) => {
  const { id: clientId } = clientData;
  const payload = {
    method: "PUT",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
    body: JSON.stringify(clientData),
  };

  const response = await fetch(`${API_URL}clients/${clientId}/`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      data.error ||
        data.detail ||
        data.description ||
        "Failed to update the client."
    );
  }

  return data;
};

export const getClient = async (token, clientId) => {
  const payload = {
    method: "GET",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
  };

  const response = await fetch(`${API_URL}clients/${clientId}/`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      data.detail || data.description || "Can not fetch the client."
    );
  }

  return data;
};

export const getClients = async (token) => {
  const payload = {
    method: "GET",
    headers: { ...DEFAULT_HEADERS, Authorization: `Token ${token}` },
  };

  const response = await fetch(`${API_URL}clients/`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error(
      data.detail || data.description || "Can not fetch clients."
    );
  }

  return data;
};

export const login = async (_, loginData) => {
  // use-http.js is a custom hook which calls an asynchronous
  // fetch function. The implementation of useHttp's requestFunction
  // uses an authentication token as argument 1, and payload data as
  // argument 2. "_" above is meant to be a throw away variableas it
  // is undefined in this scenario.
  const { email, password } = loginData;
  const payload = {
    method: "POST",
    headers: {
      ...DEFAULT_HEADERS,
      Authorization: `Basic ${base64.encode(`${email}:${password}`)}`,
    },
  };

  const response = await fetch(`${API_URL}${LOGIN_PATH}`, payload);
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.detail || data.description || "Login failed.");
  }

  return data;
};

export const validate_captcha = async (token) => {
  const status = { success: false, error: "" };

  try {
    const response = await fetch(`${API_URL}utils/validate_captcha`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${API_URL}`,
      },
      body: JSON.stringify({ token: token }),
    });

    if (!response.ok) {
      throw new Error("Request Failed!");
    }

    const data = await response.json();
    if (data.success) {
      status.success = true;
    }
  } catch (error) {
    status.success = false;
    status.error = error;
  }

  return status;
};
