import classes from "./SlideDots.module.css";

const SlideDots = (props) => {
  const dotClickHandler = (event) => {
    props.onSlideChange(+event.target.dataset.slideIndex);
  };

  const slideDots = props.slides.map((_, i) => (
    <button
      onClick={dotClickHandler}
      data-slide-index={i}
      className={`${classes.dot} ${
        i === props.activeIndex ? classes.dot__active : ""
      }`}
      key={i}
    ></button>
  ));

  return <div className={classes.dots}>{slideDots}</div>;
};

export default SlideDots;
