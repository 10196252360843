import React, { Fragment } from "react";

import classes from "./Input.module.css";

const TextArea = (props) => {
  const controlClasses = [
    classes.control,
    props.hasError ? classes.invalid : "",
  ];

  return (
    <div className={controlClasses.join(" ")}>
      <div className={classes.label__container}>
        <label htmlFor={props.name}>{props.label}</label>
      </div>
      <textarea
        rows={props.rows}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.hasError ? props.errorText : props.placeholder}
        value={props.value}
      />
    </div>
  );
};

export default TextArea;
