import { BsCheck } from "react-icons/bs";

import classes from "./Slide.module.css";

const Slide = (props) => {
  let slideBody;

  slideBody =
    typeof props.body === "string" ? (
      <p>{props.body}</p>
    ) : (
      <ul>
        {props.body.map((item, i) => (
          <li key={i}>
            <BsCheck />
            {item}
          </li>
        ))}
      </ul>
    );

  return (
    <div className={classes.slide} style={props.style}>
      <div className={classes.service}>
        <div className={classes.service__header}>
          <div className={classes.service__icon}>{props.glyph}</div>
          <h2>
            <span className="highlight">{props.title}</span>
          </h2>
        </div>
        <div className={classes.service__content}>{slideBody}</div>
      </div>
    </div>
  );
};

export default Slide;
