import React, { useState, useEffect } from "react";
import { DEFAULT_MOBILE_VIEWPORT } from "../utils/constants";

const MobileContext = React.createContext({
  isMobile: false,
  viewportWidth: "",
});

export const MobileContextProvider = (props) => {
  const [viewPortWidth, setViewPortWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Configure mobile navbar
    setViewPortWidth(
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
    );
  }, []);

  useEffect(() => {
    if (!viewPortWidth) return;

    setIsMobile(viewPortWidth < DEFAULT_MOBILE_VIEWPORT ? true : false);
  }, [viewPortWidth]);

  const context = { isMobile: isMobile };

  return (
    <MobileContext.Provider value={context}>
      {props.children}
    </MobileContext.Provider>
  );
};

export default MobileContext;
