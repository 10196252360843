import { useReducer, useCallback, useContext } from "react";
import { useSelector } from "react-redux";

export const INITIAL_STATE = {
  status: null,
  data: null,
  error: null,
};

function httpReducer(state, action) {
  if (action.type === "SEND") {
    return {
      data: null,
      error: null,
      status: "pending",
    };
  }

  if (action.type === "SUCCESS") {
    return {
      data:
        action.responseData instanceof Array && action.responseData.length === 0
          ? null
          : action.responseData,
      error: null,
      status: "completed",
    };
  }

  if (action.type === "ERROR") {
    return {
      data: null,
      error: action.errorMessage,
      status: "completed",
    };
  }

  if (action.type === "CLEAR") {
    return INITIAL_STATE;
  }

  if (action.type === "COMPLETE_REQUEST") {
    return {
      ...state,
      status: "completed",
    };
  }

  return state;
}

function useHttp(requestFunction, startWithPending = false) {
  const authToken = useSelector((state) => state.auth.token);

  const [httpState, dispatch] = useReducer(httpReducer, {
    ...INITIAL_STATE,
    status: startWithPending ? "pending" : null,
  });

  const sendRequest = useCallback(
    async (requestData) => {
      dispatch({ type: "SEND" });
      try {
        const responseData = await requestFunction(authToken, requestData);
        dispatch({ type: "SUCCESS", responseData });
      } catch (error) {
        dispatch({
          type: "ERROR",
          errorMessage: error.message || "Something went wrong!",
        });
      }
    },
    [requestFunction]
  );

  const clearState = () => {
    dispatch({ type: "CLEAR" });
  };

  const completeRequest = () => {
    dispatch({ type: "COMPLETE_REQUEST" });
  };

  return {
    sendRequest,
    ...httpState,
    clearState,
    completeRequest,
  };
}

export default useHttp;
