import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("auth_token"),
  tokenExpiration: null,
  isLoggedIn: !!localStorage.getItem("auth_token"),
  user: localStorage.getItem("user"),
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      state.tokenExpiration = action.payload.tokenExpiration;
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    logout(state) {
      state.token = null;
      state.tokenExpiration = null;
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
