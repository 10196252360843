import logoImage from "../../assets/logo.png";

import classes from "./Logo.module.css";

const Logo = (props) => {
  return (
    <div className={classes.logo}>
      <img src={logoImage} width={props.width} height={props.height} />
    </div>
  );
};

export default Logo;
