import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import classes from "./Input.module.css";

const Input = React.forwardRef((props, ref) => {
  const controlClasses = [
    classes.control,
    props.hasError ? classes.invalid : "",
    props.className ? props.className : "",
  ];

  return (
    <div
      className={controlClasses.join(" ")}
      style={{ maxWidth: props.maxWidth ? props.maxWidth : "" }}
    >
      {props.label && <label htmlFor={props.name}>{props.label}</label>}

      <input
        name={props.name}
        id={props.id}
        type={props.type}
        placeholder={props.hasError ? props.errorText : props.placeholder}
        onClick={props.onClick}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        value={props.value}
        maxLength={props.maxLength}
        autoFocus={props.autoFocus}
        readOnly={props.readOnly}
        ref={ref}
      />
    </div>
  );
});

export default Input;
