import React, { Fragment } from "react";

import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

import classes from "./SliderButton.module.css";

const SliderButton = (props) => {
  const baseClass = classes.slider__btn;

  if (props.position === "left") {
    return (
      <button
        onClick={props.onClick}
        className={`${baseClass} ${classes["slider__btn--left"]}`}
      >
        <BsArrowLeft className={classes["slider__btn--icon"]} />
      </button>
    );
  }

  if (props.position === "right") {
    return (
      <button
        onClick={props.onClick}
        className={`${baseClass} ${classes["slider__btn--right"]}`}
      >
        <BsArrowRight className={classes["slider__btn--icon"]} />
      </button>
    );
  }
};

export default SliderButton;
