const prod = {
  API_URL: "https://api.inforacer.com/v1/",
};
const dev = {
  API_URL: "http://localhost:8000/v1/",
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;

// Sort table columns based on the type of data
export const DATE_COLUMNS = ["date"];
export const NUMERIC_COLUMNS = ["duration", "balance", "rate"];

export const DEFAULT_MOBILE_VIEWPORT = 960;
export const DEFAULT_TIMEOUT = 300;
export const TIMEOUT_DATA_REFRESH = 700;
// export const
export const EMAIL_VALIDATION_REGEX =
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
