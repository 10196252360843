import { Fragment } from "react";

import classes from "./Backdrop.module.css";

const Backdrop = (props) => {
  return (
    <Fragment>
      {props.show && (
        <div className={classes.backdrop} onClick={props.onClose} />
      )}
    </Fragment>
  );
};

export default Backdrop;
