import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Modal from "../UI/Modal";
import ContactForm from "../ContactForm/ContactForm";
import { DEFAULT_TIMEOUT } from "../../utils/constants";

import classes from "./Home.module.css";

const Home = (props) => {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [contactModalIsShowing, setContactModalIsShowing] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", activateStickyNavbar);
  }, []);

  const activateStickyNavbar = () => {
    if (window !== undefined) {
      const windowHeight = window.scrollY;
      if (windowHeight < 500) setStickyNavbar(false);
      else setStickyNavbar(true);
    }
  };

  const smoothScroll = (scrollToId) => {
    setTimeout(() => {
      document.querySelector(scrollToId).scrollIntoView({ behavior: "smooth" });
    }, DEFAULT_TIMEOUT);
  };

  const openContactModalHandler = () => {
    setContactModalIsShowing(true);
  };

  const closeContactModalHandler = () => {
    setContactModalIsShowing(false);
  };

  const contactFormSubmitHandler = () => {
    setContactModalIsShowing(false);
  };

  return (
    <div className={classes.container}>
      <Modal show={contactModalIsShowing} onClose={closeContactModalHandler}>
        <ContactForm onSubmit={contactFormSubmitHandler} />
      </Modal>

      <Header
        sticky={stickyNavbar}
        onContact={openContactModalHandler}
        onSmoothScroll={smoothScroll}
      />

      <Outlet context={[openContactModalHandler]} />

      <Footer
        onSmoothScroll={smoothScroll}
        onContact={openContactModalHandler}
      />
    </div>
  );
};

export default Home;
