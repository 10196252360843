import { useNavigate } from "react-router-dom";
import classes from "./Button.module.css";

const Button = (props) => {
  const isDashboard = window.location.pathname.includes("dash");

  const btnClasses = [
    classes.button,
    props.primary ? classes.button__primary : classes.button__secondary,
    props.wide ? classes.button__wide : "",
    props.float ? classes.float__right : "",
  ];

  return (
    <button
      className={btnClasses.join(" ")}
      type={props.type}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
