import { React, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Home from "./components/Home/Home";
import Sections from "./components/Section/Sections";
import Terms from "./components/Legal/Terms";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import Redirect from "./Redirect";

const Router = (props) => {
  const url = "https://clients.myportallogin.com";

  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route index element={<Sections onContact={props.onContact} />} />
        <Route path="/legal/terms" element={<Terms />} />
        <Route path="/legal/privacy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<Redirect url={url} />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Routes>
  );
};

export default Router;
