import { Link } from "react-router-dom";

import classes from "./Footer.module.css";

import icon from "../../assets/icon.png";

const Footer = (props) => {
  const smoothScrollHandler = (event) => {
    props.onSmoothScroll(event.target.dataset.scrollTo);
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.links}>
        <ul>
          <li>
            <Link to="/" onClick={smoothScrollHandler} data-scroll-to="#about">
              About
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={smoothScrollHandler}
              data-scroll-to="#services"
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/legal/terms"
              onClick={smoothScrollHandler}
              data-scroll-to="#root"
            >
              Terms of Use
            </Link>
          </li>
          <li>
            <Link
              to="/legal/privacy"
              onClick={smoothScrollHandler}
              data-scroll-to="#root"
            >
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/" onClick={props.onContact}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div>
        <Link to="/">
          <img src={icon} />
        </Link>
      </div>
      <div className={classes.copyright}>
        <p>© Copyright 2021 InfoRacer Technologies. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
