import React, { Fragment, useEffect, useState } from "react";

import toast from "react-hot-toast";
import emailjs from "emailjs-com";

import Button from "../Button/Button";
import ReCaptcha from "../UI/ReCaptcha";
import Input from "../UI/Input";
import TextArea from "../UI/TextArea";
import useHttp from "../../hooks/use-http";
import { contact } from "../../lib/api";

import { EMAIL_VALIDATION_REGEX } from "../../utils/constants";

import classes from "./ContactForm.module.css";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => value.match(EMAIL_VALIDATION_REGEX);

const ContactForm = (props) => {
  let bread;
  const [formIsValid, setFormIsValid] = useState(false);

  const [enteredName, setEnteredName] = useState("");
  const [enteredNameIsValid, setEnteredNameIsValid] = useState(false);
  const [enteredNameTouched, setEnteredNameTouched] = useState(false);

  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredEmailIsValid, setEnteredEmailIsValid] = useState(false);
  const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);

  const [enteredPhone, setEnteredPhone] = useState("");
  const [enteredPhoneIsValid, setEnteredPhoneIsValid] = useState(false);
  const [enteredPhoneTouched, setEnteredPhoneTouched] = useState(false);

  const [enteredMessage, setEnteredMessage] = useState("");
  const [enteredMessageIsValid, setEnteredMessageIsValid] = useState(false);
  const [enteredMessageTouched, setEnteredMessageTouched] = useState(false);

  // name input
  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);

    isNotEmpty(event.target.value)
      ? setEnteredNameIsValid(true)
      : setEnteredNameIsValid(false);
  };
  const nameBlurHandler = () => setEnteredNameTouched(true);

  // email input
  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);

    isEmail(event.target.value)
      ? setEnteredEmailIsValid(true)
      : setEnteredEmailIsValid(false);
  };
  const emailBlurHandler = () => setEnteredEmailTouched(true);

  // phone input
  const phoneChangeHandler = (event) => {
    setEnteredPhone(event.target.value);

    isNotEmpty(event.target.value)
      ? setEnteredPhoneIsValid(true)
      : setEnteredPhoneIsValid(false);
  };
  const phoneBlurHandler = () => setEnteredPhoneTouched(true);

  // message textarea
  const messageChangeHandler = (event) => {
    setEnteredMessage(event.target.value);

    isNotEmpty(event.target.value)
      ? setEnteredMessageIsValid(true)
      : setEnteredMessageIsValid(false);
  };
  const messageBlurHandler = () => setEnteredMessageTouched(true);

  const nameInputIsInvalid = !enteredNameIsValid && enteredNameTouched;
  const emailInputIsInvalid = !enteredEmailIsValid && enteredEmailTouched;
  const phoneInputIsInvalid = !enteredPhoneIsValid && enteredPhoneTouched;
  const messageInputIsInvalid = !enteredMessageIsValid && enteredMessageTouched;

  const resetFormFields = () => {
    // Reset form fields
    setEnteredName("");
    setEnteredNameTouched(false);

    setEnteredEmail("");
    setEnteredEmailTouched(false);

    setEnteredPhone("");
    setEnteredPhoneTouched(false);

    setEnteredMessage("");
    setEnteredMessageTouched(false);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formIsValid) return;

    bread = toast.loading("Sending message...");

    emailjs
      .sendForm("exchange", "contact", event.target, "DaD40YZLUn2Q4X_i9")
      .then(
        (result) => {
          toast.success("Message sent!");
          props.onSubmit();
        },
        (error) => {
          toast.error("An error occurred!");
        }
      )
      .finally(
        setTimeout(() => {
          toast.dismiss(bread);
        }, 1000)
      );
  };

  useEffect(() => {
    if (
      enteredNameIsValid &&
      enteredEmailIsValid &&
      enteredPhoneIsValid &&
      enteredMessageIsValid
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    enteredNameIsValid,
    enteredEmailIsValid,
    enteredPhoneIsValid,
    enteredMessageIsValid,
  ]);

  return (
    <div className={classes.container}>
      <h1>Send us a message</h1>
      <p>Fill out the form below to send us a message.</p>
      <p>We typically respond within 8 hours.</p>
      <form className={classes.form} onSubmit={submitHandler}>
        <Input
          name="name"
          label="Your Name"
          type="text"
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          value={enteredName}
          hasError={nameInputIsInvalid}
          errorText="Enter a valid name"
        />
        <Input
          name="email"
          label="Email Address"
          type="email"
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          value={enteredEmail}
          hasError={emailInputIsInvalid}
          errorText="Enter a valid email address"
        />
        <Input
          name="phone"
          label=" Phone Number"
          type="text"
          onChange={phoneChangeHandler}
          onBlur={phoneBlurHandler}
          value={enteredPhone}
          hasError={phoneInputIsInvalid}
          errorText="Enter a valid phone number"
        />
        <TextArea
          name="message"
          label="Message"
          rows="5"
          onChange={messageChangeHandler}
          onBlur={messageBlurHandler}
          value={enteredMessage}
          hasError={messageInputIsInvalid}
          errorText="Please enter a message"
        />
        <Button type="submit">Send</Button>
      </form>
    </div>
  );
};

export default ContactForm;
